import React, {useState, useEffect} from 'react'
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";
import {useCookies} from "react-cookie";
import {graphql} from 'gatsby'
import AgeGate from "../components/AgeGate";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Seo} from '../components/Seo'

const WishpondPage = ({data}) => {
  // console.log(data)
  const {
    meta_title,
    meta_description,
    page_id,
    track_anonymous_id,
    show_header,
    show_footer
  } = data.prismicWishpondPage.data

  const [showAgeGate, setShowAgeGate] = useState(false)
  const [cookies, setCookie] = useCookies(['adult'])

  function setAgeValid() {
    setShowAgeGate(!showAgeGate)
    //set cookie
    setCookie('adult', true, [])
  }

  const [wpHref, setWpHref] = useState('')
  const [iframeHeight, setIframeHeight] = useState(null)

  const wpScript = "//cdn.wishpond.net/connect.js?merchantId=1484505&amp;writeKey=086605ba1b25"
  const wpCurHref = `https://embedded.wishpondpages.com/lp/${page_id}/`

  const wpAnalyticsScript = "https://cdn.segment.com/analytics.js/v1/qBn58ZgspH0wo5Ya3Lc0IZRT0tg4X8q4/analytics.min.js"

  const isBrowser = typeof window !== "undefined"
  const analytics = isBrowser ? window.analytics : undefined

  const mainStyles = {
    paddingTop: show_header ? '85px' : 0,
  }

  const iFrameStyles = {
    height: iframeHeight
  }

  useEffect(() => {
    setWpHref(wpCurHref)

    //append WP script
    const script = document.createElement("script")
    script.src = wpScript
    script.async = true
    document.body.appendChild(script)

    //append segment analytics script
    const analyticScript = document.createElement("script")
    analyticScript.src = wpAnalyticsScript
    analyticScript.async = true
    document.body.appendChild(analyticScript)


    setTimeout(() => {
      const iframe = document.getElementById(`wp-page-${page_id}`);
      if (iframe) {
        iframe.scrolling = 'yes';

        window.addEventListener('message', function (event) {
          if (event.data.type === 'content_height') {
            setIframeHeight(event.data.data.height)
          }
        });
      }
    }, 2000);

  }, [wpCurHref])


  useEffect(() => {
    // trackAnonymousId
    if (!analytics) {
      return
    }

    const anonymous_id = analytics.user().anonymousId() || ""

    if (track_anonymous_id) {
      const hrefWithAnonymousID = `${wpCurHref}?anonymous_id=${anonymous_id}`
      setWpHref(hrefWithAnonymousID)

    } else {
      const message = JSON.stringify({anonymous_id});
      document.getElementById(`wp-page-${page_id}`)?.contentWindow.postMessage(message, "*");
    }

  }, [analytics, wpCurHref])

  return (
      <>
        <Seo title={meta_title?.text}
             description={meta_description?.text}
        />
        {(cookies['adult'] !== "true" && !showAgeGate) ?
            <AgeGate setAgeValid={setAgeValid}/>
            :
            <>
              {show_header && <Header customPage={'wishpond-page'}/>}
              <main style={mainStyles}>
                <div
                    className="wishpond-campaign"
                    data-wishpond-id={page_id}
                    data-wishpond-href={wpHref}
                    style={iFrameStyles}
                >
                </div>
              </main>
              {show_footer && <Footer/>}
            </>
        }
      </>
  )
}

export const query = graphql`
query WishpondPageQuery ($uid: String, $id: String ) {
  prismicWishpondPage (uid: { eq: $uid }, id: { eq: $id } ) {
    _previewable
    data {
      show_header
      show_footer
      track_anonymous_id
      meta_description {
        richText
        text
        html
      }
      meta_title {
        html
        richText
        text
      }
      page_id
    }
    uid
    type
    lang
    id
    url
  }
} `


export default withPrismicPreview(WishpondPage)